import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

const TextCaseStudy = ({ data, className, spacer, wide, smallWide }) => {
  const { content, background_color, background_fill } = data || {};

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  return (
    <Section
      className={cn('text-case-study', spacer && 'text-case-study_spacer', [
        className,
      ])}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>
          <Column
            className={`offset-lg-2 col-lgplus-${wide ? '7' : '6'}`}
            lg="9"
          >
            <div
              className={cn('text-case-study__content', {
                'text-case-study__content_small-wide': smallWide,
              })}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

TextCaseStudy.defaultProps = {
  className: '',
  wide: false,
  smallWide: false,
};

TextCaseStudy.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  spacer: PropTypes.bool,
  wide: PropTypes.bool,
  smallWide: PropTypes.bool,
};

export default TextCaseStudy;
